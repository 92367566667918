import React from "react";
import ReactDOM from "react-dom";

import {
  createInstance as createOptimizelyReactInstance,
  OptimizelyProvider,
} from "@optimizely/react-sdk";

import "./index.scss";
import "antd/dist/antd.less";

import { generateOptimizelyEnvKey } from "Optimizely/service/generateOptimizelyEnvKey";

import GlobalSpinner from "./components/common/GlobalSpinner";

import getSchemaName from "utils/getSchemaName";

const rootDOM = document.getElementById("root");

const App = React.lazy(() =>
  import(
    /* webpackChunkName: "root.app" */
    "./App"
  )
);

// TODO: Move to index.html
require("typeface-roboto");

window.__OPTIMIZELY__ = window.__OPTIMIZELY__ || {};
window.__OPTIMIZELY__.sdkKey = generateOptimizelyEnvKey();
window.__OPTIMIZELY__.reactInstance = null;
window.__OPTIMIZELY__.readyResult = null;

// Move all non-pure files in here
async function postOptimizelyCallback() {
  const optimizelyReactInstance = window.__OPTIMIZELY__.reactInstance;
  ReactDOM.render(
    <React.Suspense fallback={<GlobalSpinner />}>
      <OptimizelyProvider optimizely={optimizelyReactInstance}>
        <App />
      </OptimizelyProvider>
    </React.Suspense>,
    rootDOM
  );
  window.removeEventListener("optimizely-init", postOptimizelyCallback);
}

// Attaching event Listener immediately
window.addEventListener("optimizely-init", postOptimizelyCallback);

// Check if event was missed
if (window.__OPTIMIZELY__.reactInstance) {
  postOptimizelyCallback();
} else {
  ReactDOM.render(<GlobalSpinner />, rootDOM);
}

fetch(`https://optimizely.thelevel.ai?sdkKey=${window.__OPTIMIZELY__.sdkKey}`)
  .then((response) => {
    if (!response.ok || response.status >= 400) {
      throw new Error("Bad Optimizely Response");
    }
    return response.json();
  })
  .then((datafile) => {
    window.__OPTIMIZELY__.datafile = datafile;
    return datafile;
  })
  .then((datafile) => {
    const optimizelyReactInstance = createOptimizelyReactInstance({
      datafile,
      logLevel: 4,
    });
    return optimizelyReactInstance;
  })
  .catch(() => {
    // Create default Optimizely Instance
    const optimizelyReactInstance = createOptimizelyReactInstance({
      sdkKey: window.__OPTIMIZELY__.sdkKey,
      logLevel: 4,
    });
    return optimizelyReactInstance;
  })
  .then(async (optimizelyReactInstance) => {
    const schemaName = getSchemaName();
    optimizelyReactInstance.setUser({
      id: `user_${schemaName}`,
      attributes: { tenant: schemaName },
    });
    window.__OPTIMIZELY__.reactInstance = optimizelyReactInstance;
    window.__OPTIMIZELY__.readyResult = await optimizelyReactInstance.onReady();
    return;
  })
  .finally(() => {
    window.dispatchEvent(new CustomEvent("optimizely-init"));
  });